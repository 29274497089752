(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict"; // 旧IEの警告アラート表示

function isLegacyIE() {
  var ua = navigator.userAgent;

  if (ua.indexOf("MSIE 6.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 7.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 8.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 9.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("MSIE 10.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else if (ua.indexOf("Trident/7.0") != -1) {
    alert("お使いのウェブブラウザでは当サイトを正常に閲覧できません。ブラウザのバージョンを最新にして再度アクセスしてください。");
  } else {
    return false;
  }
}

isLegacyIE(); // トップページのオススメ商品スライド表示

var mySwiper = new Swiper('.swiper-container', {
  speed: 500,
  loop: true,
  slidesPerView: 4,
  spaceBetween: 24,
  autoplay: {
    delay: 2000
  },
  breakpoints: {
    1280: {
      slidesPerView: 4
    },
    992: {
      slidesPerView: 2
    },
    768: {
      slidesPerView: 3
    },
    544: {
      slidesPerView: 2
    },
    360: {
      slidesPerView: 1
    }
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
}); // コンタクトフォームのプライバシーポリシー承認チェック

var contactAgree = document.getElementById('contact_agree');
var contactCheck = document.getElementById('contact_check');
var contactBack = document.getElementById('contact_back');

if (contactAgree) {
  window.addEventListener('load', function (event) {
    if (contactAgree.checked) {
      contactCheck.disabled = false;
    } else {
      contactCheck.disabled = true;
    }
  });
  contactAgree.addEventListener('change', function (event) {
    if (event.target.checked) {
      contactCheck.disabled = false;
    } else {
      contactCheck.disabled = true;
    }
  });
} // 会員退会承認チェック


var unsubscribeAgree = document.getElementById('unsubscribe_agree');
var unsubscribeCheck = document.getElementById('unsubscribe_check');

if (unsubscribeAgree) {
  window.addEventListener('load', function (event) {
    if (unsubscribeAgree.checked) {
      unsubscribeCheck.disabled = false;
    } else {
      unsubscribeCheck.disabled = true;
    }
  });
  unsubscribeAgree.addEventListener('change', function (event) {
    if (event.target.checked) {
      unsubscribeCheck.disabled = false;
    } else {
      unsubscribeCheck.disabled = true;
    }
  });
} // 会員登録の利用規約承認チェック


var ruleAgree = document.getElementById('rule_agree');
var ruleCheck = document.getElementById('rule_check');

if (ruleAgree) {
  window.addEventListener('load', function (event) {
    if (ruleAgree.checked) {
      ruleCheck.disabled = false;
    } else {
      ruleCheck.disabled = true;
    }
  });
  ruleAgree.addEventListener('change', function (event) {
    if (event.target.checked) {
      ruleCheck.disabled = false;
    } else {
      ruleCheck.disabled = true;
    }
  });
} // 商品購入承認チェック


var purchaseAgree = document.getElementById('purchase_agree');
var purchaseCheckNodeList = document.querySelectorAll('.purchase_check');
var purchaseCheckArray = Array.from(purchaseCheckNodeList);

if (purchaseAgree) {
  window.addEventListener('load', function (event) {
    if (purchaseAgree.checked) {
      purchaseCheckArray.forEach(function (item) {
        item.disabled = false;
      });
    } else {
      purchaseCheckArray.forEach(function (item) {
        item.disabled = true;
      });
    }
  });
  purchaseAgree.addEventListener('change', function (event) {
    if (event.target.checked) {
      purchaseCheckArray.forEach(function (item) {
        item.disabled = false;
      });
    } else {
      purchaseCheckArray.forEach(function (item) {
        item.disabled = true;
      });
    }
  });
} // お気に入りボタン状態表示切り替え


var buttons = document.querySelectorAll('.button_favorite');
var arraybuttons = Array.from(buttons);
arraybuttons.forEach(function (item) {
  item.addEventListener('click', function (event) {
    event.target.classList.toggle('__active');
  });
});
var button = document.getElementById('button_favorite');

if (button) {
  button.addEventListener('click', function (event) {
    button.classList.toggle('__active');

    if (button.classList.contains('__active')) {
      button.innerText = 'お気に入りに登録されています';
    } else {
      button.innerText = 'この商品をお気に入りに登録';
    }
  });
}

;
$(function () {
  // モーダル表示
  $(".modal_check_trigger").each(function () {
    $(this).on('click', function () {
      $("body").addClass('modal_active modal_check');
    });
  });
  $(".modal_complete_trigger").each(function () {
    $(this).on('click', function () {
      $("body").addClass('modal_active modal_complete');
    });
  });
  $(".modal_send_check_trigger").each(function () {
    $(this).on('click', function () {
      $("body").addClass('modal_send_check');
    });
  });
  $(".modal_payment_check_trigger").each(function () {
    $(this).on('click', function () {
      $("body").addClass('modal_payment_check');
    });
  });
  $(".modal_close").each(function () {
    $(this).on('click', function () {
      $("body").removeClass('modal_active modal_check modal_complete modal_send_check modal_payment_check');
    });
  }); // サムネイル表示

  $("#item_detail_thumb > .__item > .__image").each(function () {
    $(this).on('click', function () {
      var srcUrl = $(this).attr('src');
      $("#item_detail_image > .__image").attr('src', srcUrl);
      $(this).parent().siblings().removeClass('__active');
      $(this).parent().addClass('__active');
    });
  }); // ユーザーアイコン画像のインプットUI

  $("#user_icon").on('change', function (event) {
    var file = $(this).prop('files')[0];

    if (!$(".__filename").length) {
      $("#label_group").append('<span class="__filename"></span>');
    }

    $("#user_icon_label").addClass('__changed');
    $(".__filename").html(file.name);

    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var preview = document.getElementById('preview_image');
      var fileData = document.getElementById('user_icon').files[0];

      if (!fileData.type.match('image.*')) {
        alert('画像を選択してください');
        return false;
      }

      var reader = new FileReader();
      reader.addEventListener('load', function () {
        preview.src = reader.result;
      }, false);

      if (fileData) {
        reader.readAsDataURL(fileData);
      }
    }
  }); // 出品商品写真のインプットUI

  $(".input_item_image_form").each(function () {
    if ($(this).siblings('.input_item_image_preview').attr('src') === '') {
      $(this).siblings('.__label-delete').css('display', 'none');
    }

    $(this).on('change', function () {
      $(this).next('.__label-group').children('.__label').addClass('__changed');

      if ($(this).val()) {
        $(this).siblings('.__label-delete').css('display', 'block');
        $(this).siblings('.__label-delete').find('.__check').prop('checked', false);
      } else {
        $(this).siblings('.__label-delete').css('display', 'none');
      }

      if (window.File && window.FileReader && window.FileList && window.Blob) {
        var preview = $(this).prev('.input_item_image_preview');
        var fileData = $(this)[0].files[0];
        console.log(preview);

        if (!fileData.type.match('image.*')) {
          alert('画像を選択してください');
          return false;
        }

        var reader = new FileReader();
        reader.addEventListener('load', function () {
          preview[0].src = reader.result;
        }, false);

        if (fileData) {
          reader.readAsDataURL(fileData);
        }
      }
    });
  });
  $(".__label-delete > .__button > .__check").each(function () {
    $(this).on('change', function () {
      if ($(this).prop('checked')) {
        $(this).closest('.__label-delete').siblings('.input_item_image_form').val('');
        $(this).closest('.__label-delete').siblings('.__label-group').children('.__label').removeClass('__changed');
        $(this).closest('.__label-delete').css('display', 'none');
        $(this).closest('.__label-delete').siblings('.input_item_image_preview').attr('src', '');
      }
    });
  }); // 商品購入ラジオボタンUI 

  $(".radio_button_label").each(function () {
    $(this).on('click', function () {
      if ($(this).children('.radio_button:checked').val()) {
        $(this).addClass('__checked');
        $(this).siblings('.radio_button_label').removeClass('__checked');
      }
    });
  }); // 検品手数料表示切り替え

  $(".inspection_target").on('change', function () {
    if ($("#inspection_check:checked").val()) {
      $("#inspection_costs_on").css('display', 'block');
      $("#inspection_costs_off").css('display', 'none');
    } else {
      $("#inspection_costs_on").css('display', 'none');
      $("#inspection_costs_off").css('display', 'block');
    }
  }); // 商品一覧ソート順変更リストUI

  $("#sort_switch > .__link > label > .__check").each(function () {
    $(this).on('change', function () {
      if ($(this).prop('checked')) {
        $(this).closest('.__link').siblings().removeClass('__active');
        $(this).closest('.__link').addClass('__active');
      }
    });
  }); // 出品中商品削除の商品データ受け渡し

  $(".post-items-delete-check").each(function () {
    $(this).on('click', function () {
      var dataHref = $(this).attr('data-href');
      var dataName = $(this).attr('data-name');
      $("#post-items-delete-submit").attr('href', dataHref);
      $("#post-items-delete-name").text(dataName);
    });
  }); // 出品取引での商品発送時の商品データ受け渡し

  $(".selling-items-send-check").each(function () {
    $(this).on('click', function () {
      event.preventDefault();
      var dataHref = $(this).attr('data-href');
      var dataName = $(this).attr('data-name');
      $("#selling-items-send-submit").attr('href', dataHref);
      $("#selling-items-send-name-check").text(dataName);
      $("#selling-items-send-name-complete").text(dataName);
    });
  }); // 出品取引での代金着金時の商品データ受け渡し

  $(".selling-items-payment-check").each(function () {
    $(this).on('click', function (event) {
      event.preventDefault();
      var dataHref = $(this).attr('data-href');
      var dataName = $(this).attr('data-name');
      $("#selling-items-payment-submit").attr('href', dataHref);
      $("#selling-items-payment-name-check").text(dataName);
      $("#selling-items-payment-name-complete").text(dataName);
    });
  }); // 購入取引での商品受領時の商品データ受け渡し

  $(".trading-items-receive-check").each(function () {
    $(this).on('click', function (event) {
      event.preventDefault();
      var dataHref = $(this).attr('data-href');
      var dataName = $(this).attr('data-name');
      $("#trading-items-receive-submit").attr('href', dataHref);
      $("#trading-items-receive-name-check").text(dataName);
      $("#trading-items-receive-name-complete").text(dataName);
    });
  }); // お気に入り登録解除の商品データ受け渡し

  $(".favorite-delete-check").each(function () {
    $(this).on('click', function () {
      var dataHref = $(this).attr('data-href');
      var dataName = $(this).attr('data-name');
      $("#favorite-delete-submit").attr('href', dataHref);
      $("#favorite-delete-name").text(dataName);
    });
  });
});
$(window).on('load', function () {
  // サムネイル画像トリミング
  $(".thumbnail_trim").each(function () {
    $(this).css({
      'height': $(this).width() + 'px'
    });
  }); // 商品金額入力時の利益額自動計算

  var postPrice = $("#post_price");
  var priceResult = $("#price_result");
  var priceIncome = $("#price_income");
  var late = 95;
  var priceValue = $("#post_price").val();
  var priceSeparated = Number(priceValue).toLocaleString();
  var priceIncomeValue = Math.floor(priceValue * (late / 100));
  var priceIncomeSeparated = Number(priceIncomeValue).toLocaleString();

  if (isNaN(priceValue)) {
    priceResult.text(Number(0));
    priceIncome.text(Number(0));
  } else {
    priceResult.text(priceSeparated);
    priceIncome.text(priceIncomeSeparated);
  }

  postPrice.on('blur', function () {
    var priceValue = $(this).val();
    var priceSeparated = Number(priceValue).toLocaleString();
    var priceIncomeValue = Math.floor(priceValue * (late / 100));
    var priceIncomeSeparated = Number(priceIncomeValue).toLocaleString();

    if (isNaN(priceValue)) {
      priceResult.text(Number(0));
      priceIncome.text(Number(0));
      $("#price_calculate_error").css('display', 'block').text('正しく金額を入力してください');
    } else {
      priceResult.text(priceSeparated);
      priceIncome.text(priceIncomeSeparated);
      $("#price_calculate_error").css('display', 'none').text('');
    }
  }); // 検品手数料表示切り替え

  if ($("#inspection_check:checked").val()) {
    $("#inspection_costs_on").css('display', 'block');
    $("#inspection_costs_off").css('display', 'none');
  } else {
    $("#inspection_costs_on").css('display', 'none');
    $("#inspection_costs_off").css('display', 'block');
  }
});

},{}]},{},[1]);
